import moment from "moment";

import { Devis, DevisFormData, ModelDevis, UserInfos } from "../../redux/types";
import { formatAmout } from "../../utils/formatter";
import { getAmount, getDateEcheance, getNomCom, normaliseTvas } from "./hooks";

type Dev = Devis | DevisFormData;

function minifyHTML(html: string) {
  return html
    .replace(/\n/g, "")
    .replace(/\s+/g, " ")
    .replace(/>\s+</g, "><")
    .replace(/>,</g, "><")
    .replace(/<!--.*?-->/g, "");
}

export function generateDevis(
  item: Dev,
  model: ModelDevis,
  user: UserInfos,
  signature: string
) {
  const html = minifyHTML(getHtml(item, model, user, signature));
  return html;
}

function getOr(cond: boolean, html?: string, or = "") {
  if (cond) return html;
  return or;
}

function getDevisSenderInfosImage(model: ModelDevis) {
  const getEmail = () => {
    if (model.type_email === "Personnalisé") {
      return {
        label: model.libelle_email,
        value: model.email,
      };
    }
    return {
      label: `Mail ${model.type_email}`,
      value: model.email,
    };
  };
  const getPhone = () => {
    if (model.type_telephone === "Personnalisé") {
      return {
        label: model.libelle_telephone,
        value: model.email,
      };
    }
    return {
      label: `N° Tél ${model.type_telephone}`,
      value: model.telephone,
    };
  };
  const email = getEmail();
  const phone = getPhone();
  return `
			<div class="section-facture-info-item-left">
       ${getOr(
         !!model.logo,
         `<img style="width: 35px;height: auto;" src="${model.logo}" alt="Logo" class="logo-app-facture">`
       )}
                <p class="title-section-facture-info-item">${getNomCom(
                  model
                )}</p>
					 ${model.adresss.map(
             (ad, index) =>
               `<p class="text-info-contact-app">${getOr(
                 index === 0,
                 `<span style="font-weight: 500;">Adresse : </span>`,
                 ""
               )}${ad.trim()}</p>`
           )}
                <p class="text-info-contact-app"><span style="font-weight: 500;">${
                  model.type_siret
                } : </span> ${model.siret}</p>
                <p class="text-info-contact-app"><span style="font-weight: 500;">Code APE : </span> ${
                  model.ape
                }</p>
                <p class="text-info-contact-app"> <span style="font-weight: 500;">${
                  email.label
                } : </span>${email.value}</p>
    <p class="text-info-contact-app"><span style="font-weight: 500;">${
      phone.label
    } : </span>${phone.value}</p></div>`;
}

function getDevisClientInfos(item: Dev) {
  const date_ec = getDateEcheance(item);
  return `
			<div class="section-facture-info-item-right">
      <h3 class="title-head-facture">Devis <span class="text-head-facture">
                    <span style="color: rgba(0, 0, 0, 0.80);">N°-</span>
                    <span style="color: #02B4C0;">${getOr(
                      !!item.numero,
                      item?.numero
                    )}</span>
                </span></h3>
                <p class="info-contact-app-text-right">
                    <span style="font-weight: 500;">Date du devis : </span> 
                    <span style="font-weight: 400;">${moment(
                      item.created_at
                    ).format("DD/MM/YYYY")}</span>
                </p>
                
                <p class="info-contact-app-text-right">
                    <span style="font-weight: 500;">Code client : </span> 
                    <span style="font-weight: 400;">${item.numero_client}</span>
                </p>
                <p class="info-contact-app-text-right">
                    <span style="font-weight: 500;">Nom client : </span> 
                    <span style="font-weight: 400;">${item.civilite} ${
    item.prenom_client
  } ${item.nom_client}</span>
                </p>
                <p class="info-contact-app-text-right">
                    <span style="font-weight: 500;">Adresse : </span> 
                    <span style="font-weight: 400;">${item.adresse}</span>
                </p>
                ${getOr(
                  !!item.email_reception,
                  `<p class="info-contact-app-text-right">
                    <span style="font-weight: 500;">Email : </span> 
                    <span style="font-weight: 400;">${item.email_reception}</span>
                </p>`
                )}
               ${getOr(
                 !!item.telephone_reception,
                 ` <p class="info-contact-app-text-right">
                    <span style="font-weight: 500;">Téléphone : </span> 
                    <span style="font-weight: 400;">${item.telephone_reception}</span>
                </p>`
               )}
                <p class="text-alert-devis-facture">Devis valable jusqu'à : ${date_ec.format(
                  "DD/MM/YYYY"
                )}</p>
            </div>
		`;
}

function getDevisProducts(item: Dev) {
  const hasRemise = !!item.donnees?.filter(
    (d) => !!d.montant_remise && getAmount(d.montant_remise) > 0
  )?.length;
  const hasUnite = !!item.donnees?.filter((d) => !!d.unite)?.length;
  return `<table>
              <thead>
                <tr>
                  <th>Désignation</th>
                  <th>Quantité</th>
                  ${getOr(hasUnite, `<th>Unité</th>`)}
                  <th>Prix unitaire</th>
                  <th>Montant HT</th>
                  ${getOr(
                    hasRemise,
                    `<th>Remise</th>
                  <th>Montant HT Remisé</th>`
                  )}
                  <th>% TVA</th>
                  <th>Montant TTC</th>
                </tr>
              </thead>
              <tbody>
              ${item?.donnees?.map(
                (it) => `<tr>
                  <td>
                    ${it.libelle}
                  </td>
                  <td>${it.quantite}</td>
                  ${getOr(hasUnite, `<td>${it.unite ?? "-"}</td>`)}
                  <td>${formatAmout(it.prix_unitaire)}</td>
                  <td>${formatAmout(it.prix_ht)}</td>
                  ${getOr(
                    hasRemise,
                    `<td>${formatAmout(it.montant_remise)}</td>
                  <td>${formatAmout(it.montant_after_remise)}</td>`
                  )}
                  <td>${it.taux_tva}%</td>
                  <td>${formatAmout(it.montant_ttc)}</td>
                </tr>`
              )}
              </tbody>
            </table>`;
}

function getDevisEcheance(item: Dev) {
  const getRow = (it: Devis["acoumpt"] | undefined) => {
    return `<div class="content-tr-two-table-content-left-facture">
                        <div class="tr-two-table-content-left-facture">${
                          it?.label ?? ""
                        }</div>
                        <div class="tr-two-table-content-left-facture">${
                          it?.date ?? ""
                        }</div>
                        <div class="tr-two-table-content-left-facture">${
                          it?.montant ? formatAmout(it.montant) : ""
                        }</div>
                    </div>`;
  };
  return `<div class="content-tr-one-table-content-left-facture">
                        <div class="tr-one-table-content-left-facture"></div>
                        <div class="tr-one-table-content-left-facture">Date échéance</div>
                        <div class="tr-one-table-content-left-facture">Montant échéance</div>
                    </div>
                    ${getRow(item.acoumpt)}
                    ${getOr(!!item.echeance1, getRow(item.echeance1))}
                    ${getOr(!!item.echeance2, getRow(item.echeance2))}
                    `;
}

function getDevisTva(item: Dev) {
  const tvas = normaliseTvas(item.tva ?? []);
  return `<div class="content-tr-one-table-content-left-facture">
                        <div class="tr-one-table-content-left-facture">Base  HT</div>
                        <div class="tr-one-table-content-left-facture">Taux TVA</div>
                        <div class="tr-one-table-content-left-facture">Montant TVA</div>
                    </div>
                    ${tvas.map(
                      (
                        it
                      ) => `<div class="content-tr-two-table-content-left-facture">
                        <div class="tr-two-table-content-left-facture">${formatAmout(
                          it.base_ht
                        )}</div>
                        <div class="tr-two-table-content-left-facture">${
                          it.tva
                        }</div>
                        <div class="tr-two-table-content-left-facture">${formatAmout(
                          it.montant_tva
                        )}</div>
                    </div>`
                    )}
                    
                    `;
}

function getDevisMontant(item: Dev) {
  const hasRemise = !!item.remise && getAmount(item.remise) > 0;
  const hasTva = !!item.total_tva && getAmount(item.total_tva) > 0;
  const renderRow = (
    title: string,
    value: string
  ) => `<div class="content-tr-two-table-content-left-facture">
                        <div class="tr-one-table-content-left-facture">${title}</div>
                        <div class="tr-two-table-content-left-facture">${value}</div>
                    </div>`;
  return `
    ${renderRow("Total HT", formatAmout(item.total_ht))}
    ${hasTva ? renderRow("Total  TVA", formatAmout(item.total_tva)) : ""}
    ${hasRemise ? renderRow("Remise", formatAmout(item.remise)) : ""}
    ${
      hasRemise
        ? renderRow("Total HT remisé", formatAmout(item.total_ht_after_remise))
        : ""
    }
    ${hasTva ? renderRow("Total  TTC", formatAmout(item.total_ttc)) : ""}
    ${renderRow("Net payer", formatAmout(item.net_payer))}`;
}

const getTypeDevis = (item: Dev) => {
  return ` <span style="color: #000;">${item.type_devis} - Montant TTC :</span>
      <span style="color: #6A9CE8;">${formatAmout(
        item.type_devis === "Gratuit" ? "0" : item.montant
      )}</span>`;
};

function getDates(item: Dev, model: ModelDevis, user: UserInfos) {
  return `<div class="bloc-left-footer-facture">
                        <p class="text-bloc-left-footer-facture">
                         <span> Durée des travaux : </span>
                        <span style="color: #6A9CE8;">${item.duree} ${
    item.type_duree
  }</span>
                        </p>
                        <p class="text-bloc-left-footer-facture">
                            <span>Date de debut des travaux : </span>
                            <span style="color: #6A9CE8;">${
                              item.date_debut
                            }</span>
                        </p>
                    </div>
                    <div class="bloc-left-footer-facture my-4">
                        <p class="text-bloc-left-footer-facture">Assurance souscrite au titre de l’activité</p>
                        ${getOr(
                          !!model.nom_assurance,
                          `<p class="text-info-contact-app"> <span style="font-weight: 500;">Nom : </span>${model.nom_assurance}</p>`
                        )}
                        ${getOr(
                          !!model.numero_assurance,
                          `<p class="text-info-contact-app"> <span style="font-weight: 500;">Numéro : </span>${model.numero_assurance}</p>`
                        )}
                        
                    </div>
                         
                    
                    <div class="bloc-left-footer-facture my-4">
                        <p class="text-bloc-left-footer-facture">Signature électronique</p>
                        <div class="content-signature-facture">
                          ${getOr(
                            !!user.signature,
                            `<img src="${user.signature}" style="width: 45px;height: auto;" alt="Signature" />`
                          )}
                        </div>
                       
                        </div>
                         ${getOr(
                           !!model.tampon,
                           `<p style="text-align: center"><img style="width: auto;height: 100px" src="${model.tampon}" alt="Logo" class="logo-app-facture"></p>`
                         )}
                    `;
}

export const getHtml = (
  item: Dev,
  model: ModelDevis,
  user: UserInfos,
  signature: string
) => {
  return `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    <title>Facture</title>
    ${styles}
</head>
<body>
    <div class="container-facture-body-page">
        
        <div class="container-section-facture-info">
            ${getDevisSenderInfosImage(model)}
            ${getDevisClientInfos(item)}
        </div>
        <div class="table-wrapper">
          <p class="text-alert-devis-facture" style="margin-bottom: 5px;">${
            item.type_operation
          }</p>
            ${getDevisProducts(item)}
        </div>
        <div class="container-list-table-facture">
            <div class="list-tabe-facture-item">
                <div class="table-content-left-facture facture-table-one">
                    ${getDevisEcheance(item)}
                     <div class="container-footer-facture-section" style="margin-top: 20px">
           <!-- <p class="title-footer-section-facture my-4">
               ${getTypeDevis(item)}
            </p> -->
            <div class="section-footer-facture">
                <div class="facture-section-footer-left">
                    ${getDates(item, model, user)}
                </div>
                
            </div>
        </div>
                </div>
                <div class="table-content-left-facture facture-table-two">
                    ${getDevisTva(item)}
                </div>
                <div class="table-content-left-facture-three facture-table-three">
                    ${getDevisMontant(item)}
                    <div style="margin-top: 20px">
                    <div class="bloc-left-footer-facture">
                        <p class="text-bloc-left-footer-facture">Signature du client</p>
                        <div class="content-signature-facture">
                           ${getOr(
                             !!signature,
                             `<img src="${signature}" style="width: 45px;height: auto;" alt="Signature" />`
                           )}
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
       
    </div>
</body>
</html>`;
};

const styles = `<style>
        body {
            background: #fff;
            padding: 5px 10px 5px 10px;
        }
        .container-facture-body-page{
          page-break-inside: avoid;
        }
        .container-header-facture {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        .content-info-facture-head {
            margin: auto;
            text-align: right;
        }
        .title-head-facture {
            color: #000;
font-family: "Poppins";
font-size: 20.77px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
margin: 0;
        }
        .text-head-facture {
            
font-family: "Poppins";
font-size: 15.692px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
margin: 0;
        }
        .container-section-facture-info {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 30px 0;
        }
        .title-section-facture-info-item {
            color: #333;
font-family: "Poppins";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin: 0;
        }
        .text-info-contact-app {
            color: #333;
font-family: "Poppins";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
        }
        .info-contact-app-text-right {
            color: #333;
font-family: "Poppins";
font-size: 13px;
font-style: normal;
line-height: normal;
margin: 0;
        }
        .text-alert-devis-facture {
            color: #4A2FD7;
font-family: "Poppins";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
padding-top: 20px;
}
.table-wrapper{
margin: 10px 0 5px;
}
.table-wrapper::-webkit-scrollbar {
  height: 8px;
}
.table-wrapper::-webkit-scrollbar-track {
  background: #828282;
  border-radius: 40px;
}

.table-wrapper table {
  
  border-collapse: collapse;
  text-align: center;
  width: 100%;
  border: 1px solid #828282;
}

.table-wrapper table th,
.table-wrapper table td {
  padding: 10px;
  border: 1px solid #828282;
}
.table-wrapper table td {
    color: #000;
font-family: "Poppins";
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
background: #8282820d;
}

.table-wrapper table th {
    color: #000;
font-family: "Poppins";
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
  background: #82828287;
  border: 1px solid #828282;
}
.table-wrapper table td:first-child, .table-wrapper table th:first-child {
  
  text-align: left;
}

.table-credits {
  font-size: 12px;
  margin-top: 5px;
}
.tr-one-table-content-left-facture {
    border-radius: 4px;
border: 0.5px solid #DADADA;
background: rgba(196, 196, 196, 0.10);
color: #000;
text-align: center;
font-family: "Poppins";
font-size: 7px;
font-style: normal;
font-weight: 500;
line-height: 7px; 
width: 60px;
height: 25px;
padding: 5px 10px;
display: flex;
justify-content: center;
align-items: center;
}
.tr-two-table-content-left-facture {
    color: #333;
text-align: center;
font-family: "Poppins";
font-size: 7px;
font-style: normal;
font-weight: 400;
line-height: 7px;
border-radius: 4.681px;
border: 0.585px solid #DADADA;
width: 60px;
height: 25px;
padding: 5px 10px;
background: #fff;
display: flex;
justify-content: center;
align-items: center;
}
.facture-table-one .tr-two-table-content-left-facture:first-child {
    background: rgba(196, 196, 196, 0.10);
    font-weight: 500;
}
.facture-table-one .tr-one-table-content-left-facture:first-child {
    background: #C4C4C40D;
}
.content-tr-one-table-content-left-facture, .content-tr-two-table-content-left-facture {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: 2px;
}
.list-tabe-facture-item {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.container-list-table-facture {
    margin: 15px 0;
}
.title-footer-section-facture {
    padding-bottom: 50PX;
font-family: "Poppins";
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 7.063px;
}
.section-footer-facture {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}
.bloc-left-footer-facture {
    border-radius: 8px;
border: 1px solid rgba(0, 0, 0, 0.22);
background: #fff;
padding: 10px 20px;
}
.text-bloc-left-footer-facture {
    color: #000;
font-family: "Poppins";
font-size: 9px;
font-style: normal;
font-weight: 500;
line-height: 7.063px; 
text-decoration-line: underline;
} 
.my-4 {
    margin: 10px 0;
}
.content-signature-facture {
    height: 45px;
    width: 120px;
    text-align: center;
}
    </style>`;
